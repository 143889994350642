/* banner css */

.bannerTextWidth {
    position: relative;
    width: 130%;
}


@media(max-width: 1200px) {
    .h2 {
        font-size: 50px;
    }

    .bannerTextWidth {
        left: -90px;
    }
}

@media(max-width: 1100px) {
    .h2 {
        font-size: 40px;
    }

    .bannerTextWidth {
        left: -20px;
    }
}


@media(max-width: 1100px) {
    .bannerTextWidth {
        top: 150px;
        left: 0px;
    }

    .pusherDiv {
        padding-top: 80px;
    }
}

@media(max-width: 800px) {
    .bannerTextWidth {
        top: 200px;
    }

    .pusherDiv {
        padding-top: 150px;
    }

}

@media(max-width: 767px) {
    .bannerTextWidth {
        top: 100px;
        max-width: 80%;
    }

    .pusherDiv {
        padding-top: 70px;
    }

}

@media(max-width: 700px) {
    .bannerTextWidth {
        max-width: 80%;
        top: 100px;
    }

    .pusherDiv {
        padding-top: 100px;
    }
}

@media(max-width: 650px) {
    .h2 {
        max-width: 80%;
    }

    .bannerTextWidth {
        max-width: 80%;
        top: 160px;
    }

    .pusherDiv {
        padding-top: 140px;
    }
}


@media(max-width: 600px) {
    .bannerTextWidth {
        max-width: 100%;
        top: 200px;
    }

    .pusherDiv {
        padding-top: 150px;
    }
}

@media(max-width: 550px) {
    .h2 {
        max-width: 100%;
    }

    .bannerTextWidth {
        max-width: 100%;
        top: 270px;
    }

    .pusherDiv {
        padding-top: 230px;
    }
}

@media(max-width: 500px) {
    .h2 {
        font-size: 40px;
    }

    .bannerTextWidth {
        top: 320px;
    }

    .pusherDiv {
        padding-top: 300px;
    }
}


/* banner css ends */