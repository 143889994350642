/* banner css */

.bannerTextWidth {
    position: relative;
}


@media(max-width: 800px) {
    .bannerTextWidth {
        max-width: 60%;
        left: 0px;
    }

    .pusherDiv {
        padding-top: 20px;
    }

}

@media(max-width: 700px) {
    .bannerTextWidth {
        max-width: 50%;
        top: 50px;
    }

    .pusherDiv {
        padding-top: 50px;
    }

}

@media(max-width: 600px) {
    .bannerTextWidth {
        left: 0;
        max-width: 100%;
        top: 120px;
    }

    .pusherDiv {
        padding-top: 100px;
    }
}


@media(max-width: 500px) {
    .bannerTextWidth {
        top: 150px;
    }

    .pusherDiv {
        padding-top: 150px;
    }
}

@media(max-width: 400px) {
    .bannerTextWidth {
        top: 170px;
    }

    .pusherDiv {
        padding-top: 180px;
    }
}


/* banner css ends */