/* banner css */

.bannerTextWidth {
    position: relative;
}


@media(max-width: 767px) {
    .bannerTextWidth {
        max-width: 75%;
        top: 30px;
        left: -90px;
    }

    .pusherDiv {
        padding-top: 30px;
    }

}

@media(max-width: 700px) {
    .bannerTextWidth {
        max-width: 75%;
        left: -40px;
        top: 130px;
    }

    .pusherDiv {
        padding-top: 100px;
    }
}

@media(max-width: 600px) {
    .bannerTextWidth {
        left: 0;
        top: 150px;
    }

    .pusherDiv {
        padding-top: 120px;
    }
}

@media(max-width: 550px) {
    .bannerTextWidth {
        max-width: 100%;
        top: 180px;
    }

    .pusherDiv {
        padding-top: 140px;
    }
}

@media(max-width: 550px) {
    .bannerTextWidth {
        max-width: 100%;
        top: 220px;
    }

    .pusherDiv {
        padding-top: 200px;
    }
}


/* banner css ends */