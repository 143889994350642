:root {
	/* text color */
	--text-primary: #dcbae3;
	--text-secondary: #e52165;

	--text-dark: #2e2e2e;
	--text-dark-contrast: #454eaa;

	--text-light: #ffffff;
	--text-grey: #9e9e9e;
	--text-grey2: #5e5e5e;

	/* button color */
	--button-theme: #dcbae3;
	--button-theme-dark: #e52165;
	--button-dark: #2e2e2e;

	/* background color */
	--bg-theme: rgb(220, 186, 227);
	--bg-theme-05: rgba(220, 186, 227, 0.5);
	--bg-theme-dark: #000000;
	--bg-grey: #9e9e9e;
	--bg-dark: #9e9e9e;
}

*::selection {
	background-color: grey;
}

body,
li {
	color: var(--text-grey2);
}

a:hover {
	text-decoration: none;
}

h2,
h2 a,
h2 a:hover {
	color: var(--text-dark);
}

h3,
h3 a,
h3 a:hover {
	color: var(--text-dark);
}

h4,
h4 a,
h4 a:hover {
	color: var(--text-dark);
}

h5,
h5 a,
h5 a:hover {
	color: var(--text-dark);
}

h6,
h6 a,
h6 a:hover,
a.content_link .title {
	color: var(--text-dark);
}

.text-light {
	color: var(--text-light) !important;
}

.text-grey {
	color: var(--text-grey2) !important;
}

.text-dark {
	color: var(--text-dark) !important;
}

.text-error {
	color: #fb5455;
}

.text-success {
	color: #a5de5c;
}

.text-warning {
	color: #9b812e;
}

.bg-error {
	background-color: #fb5455;
	color: #8d0d0d;
}

.bg-success {
	background-color: #a5de5c;
	color: #508807;
}

.bg-warning {
	background-color: #9b812e;
	color: #5a4910;
}

.icon_box .icon_wrapper,
.icon_box a .icon_wrapper {
	color: var(--text-primary);
}

.icon_box:hover .icon_wrapper:before,
.icon_box a:hover .icon_wrapper:before {
	background-color: var(--bg-theme-05);
	color: var(--text-light) !important;
}

.bg-theme {
	background-color: var(--bg-theme) !important;
}

.bg-theme05 {
	background-color: var(--bg-theme-05) !important;
}

.home_services_section .how_it_works {
	box-shadow: 3px 3px 6px var(--bg-dark);
	padding: 20px 10px;
	margin-bottom: 20px;
}

@media (min-width: 768px) {
	.d-xl-none {
		display: none !important;
	}

	.d-md-flex {
		display: flex !important;
	}
}

.row-reverse {
	flex-direction: row-reverse !important;
	-ms-flex-direction: row-reverse !important;
}

.icon_box.icon_position_left {
	padding-left: 135px;
}

#Top_bar .menu > li > a span:not(.description) {
	padding: 0 10px;
}

.faq .question:before {
	background-color: var(--bg-theme);
}

.image_frame {
	border-color: var(--bg-theme);
}

form button:disabled {
	cursor: not-allowed;
	background-color: var(--bg-grey);
}

.fb-post.fb_iframe_widget.fb_iframe_widget_fluid span {
	width: 100% !important;
}

.pricing-box.custom-pricing-box .plan-inside ul li {
	display: flex;
	justify-content: space-between;
}

.pricing-box.custom-pricing-box .plan-inside ul li > div:first-child {
	flex-grow: 1;
	text-align: left;
}

.pricing-box.custom-pricing-box .plan-inside ul li > div:last-child {
	text-align: right;
}

.message {
	font-size: 12px;
	min-height: 10px;
	line-height: 10px;
	margin-top: 3px;
}

textarea.text-error,
input.text-error {
	border-color: red;
}

/* header css starts */
a.action_button {
	background-color: var(--text-primary);
}

a.action_button:hover {
	box-shadow: inset 0 0 0 100px rgb(0 0 0 / 20%);
}

#Top_bar .menu > li.current-menu-item > a,
#Top_bar .menu > li.current-menu-item > a {
	color: var(--text-primary);
}

#Top_bar .menu > li > a,
#Top_bar #menu ul li.submenu .menu-toggle {
	color: var(--text-dark);
}

#Top_bar .menu > li:hover > a {
	color: var(--text-primary);
}

#Top_bar #logo img {
	max-width: 200px;
}

#Top_bar .custom-width {
	width: 100% !important;
	max-width: 100% !important;
}

@media (max-width: 400px) {
	#Top_bar #logo img {
		max-width: 180px;
	}
}

@media (min-width: 768px) {
	#rev_slider_1_1 {
		margin-top: 120px !important;
	}
}

/* header css ends */

/* footer css starts */

#Footer .theme-bakground {
	background-color: var(--bg-theme);
	color: var(--text-light);
	/* color: var(--text-secondary); */
}

#Footer .white-bakground {
	background-color: #ffffff;
}

#Footer h1,
#Footer h1 a,
#Footer h1 a:hover,
#Footer h2,
#Footer h2 a,
#Footer h2 a:hover,
#Footer h3,
#Footer h3 a,
#Footer h3 a:hover,
#Footer h4,
#Footer h4 a,
#Footer h4 a:hover,
#Footer h5,
#Footer h5 a,
#Footer h5 a:hover,
#Footer h6,
#Footer h6 a,
#Footer h6 a:hover {
	color: var(--text-dark);
}

#Footer a {
	color: var(--text-grey);
}

#Footer a:hover {
	color: var(--text-light);
}

#Footer .white-bakground a:hover {
	color: var(--text-dark);
}

a#back_to_top.button.button_js {
	background-color: var(--bg-theme);
}

/* footer css ends */

/* homepage css starts */
a.button_theme,
a.tp-button.button_theme,
button,
input[type='submit'],
input[type='reset'],
input[type='button'] {
	background-color: var(--button-theme);
}

a.button_theme_dark {
	background-color: var(--button-dark);
	border: 2px solid var(--button-dark);
	color: white;
}

a.button_theme_dark:hover {
	background-color: var(--button-theme);
	border: 2px solid var(--button-dark);
	color: var(--button-dark);
}

.section.mcb-section.mcb-section-wjiyl5j4w {
	padding-top: 110px;
	padding-bottom: 70ox;
	background-color: var(--bg-theme);

	color: #fff;
}

.section.mcb-section.mcb-section-wjiyl5j4w h2,
.section.mcb-section.mcb-section-wjiyl5j4w p {
	color: white;
}

.flex-row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	height: 100%;
}

.flex-column {
	width: 100%;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
	height: 100%;
	box-sizing: border-box;
}

@media (min-width: 768px) {
	.flex-column {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
}

@media (min-width: 992px) {
	.flex-column {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
}

@media (min-width: 1200px) {
	.flex-column {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
}

.items {
	height: 100%;
	flex-grow: 1;
	flex-basis: 1;
}

/* homepage css ends */
