/* banner css */

.bannerTextWidth {
    position: relative;
}


@media(max-width: 1200px) {
    .bannerTextWidth {
        top: 100px;
    }

}

@media(max-width: 1100px) {
    .bannerTextWidth {
        max-width: 80%;
        top: 80px;
    }

}

@media(max-width: 1000px) {
    .bannerTextWidth {
        max-width: 100%;
        top: 220px;
    }

    .pusherDiv {
        padding-top: 120px;
    }

}

@media(max-width: 900px) {
    .bannerTextWidth {
        top: 220px;
    }

}

@media(max-width: 767px) {
    .bannerTextWidth {
        top: 0;
        max-width: 75%;
    }

    .pusherDiv {
        padding-top: 20px;
    }

}

@media(max-width: 700px) {
    .bannerTextWidth {
        max-width: 80%;
        top: 150px;
    }

    .pusherDiv {
        padding-top: 120px;
    }
}

@media(max-width: 600px) {
    .bannerTextWidth {
        max-width: 100%;
        top: 200px;
    }

    .pusherDiv {
        padding-top: 170px;
    }
}

@media(max-width: 400px) {
    .bannerTextWidth {
        top: 270px;
    }

    .pusherDiv {
        padding-top: 240px;
    }
}


/* banner css ends */