/* banner css */

.bannerTextWidth {
    position: relative;
    width: 120%;
    max-width: 120%;
}


@media(max-width: 767px) {
    .bannerTextWidth {
        max-width: 75%;
        padding-bottom: 10%;
        top: 90px;
    }

    .pusherDiv {
        padding-top: 60px;
    }

}

@media(max-width: 700px) {
    .bannerTextWidth {
        max-width: 75%;
        top: 30px;
    }

    .pusherDiv {
        padding-top: 30px;
    }
}

@media(max-width: 600px) {
    .bannerTextWidth {
        max-width: 100%;
        top: 200px;
    }

    .pusherDiv {
        padding-top: 150px;
    }
}

@media(max-width: 550px) {
    .bannerTextWidth {
        top: 230px;
    }

    .pusherDiv {
        padding-top: 200px;
    }
}


/* banner css ends */