/* banner css */
@media(min-width: 768px) {
    .bannerMB120 {
        background: 'transparent';
        padding: 0;
        margin: '0 auto';
        margin-bottom: 120px !important;
    }
}

@media(max-width:767px) {
    .mdDownNone {
        display: none;
    }
}

/* banner css ends */