/* banner css */

.bannerTextWidth {
    position: relative;
}


@media(max-width: 800px) {
    .bannerTextWidth {
        top: 100px;
    }

}

@media(max-width: 767px) {
    .bannerTextWidth {
        top: 0;
        max-width: 75%;
    }

}

@media(max-width: 700px) {
    .bannerTextWidth {
        max-width: 75%;
        top: 30px;
    }

    .pusherDiv {
        padding-top: 30px;
    }
}

@media(max-width: 600px) {
    .bannerTextWidth {
        max-width: 100%;
        top: 140px;
    }

    .pusherDiv {
        padding-top: 100px;
    }
}

@media(max-width: 400px) {
    .bannerTextWidth {
        top: 200px;
    }

    .pusherDiv {
        padding-top: 170px;
    }
}


/* banner css ends */