/* banner css */

.bannerTextWidth {
    position: relative;
}


@media(max-width: 1200px) {
    .bannerTextWidth {
        top: 100px;
    }

}

@media(max-width: 1100px) {
    .bannerTextWidth {
        max-width: 80%;
        top: 80px;
    }

}

@media(max-width: 1000px) {
    .bannerTextWidth {
        max-width: 100%;
        top: 100px;
    }

    .pusherDiv {
        padding-top: 20px;
    }

}


@media(max-width: 850px) {
    .bannerTextWidth {
        top: 170px;
    }

    .pusherDiv {
        padding-top: 100px;
    }

}

@media(max-width: 767px) {
    .bannerTextWidth {
        top: 0;
        left: -85px;
        max-width: 70%;
    }

    .pusherDiv {
        padding-top: 20px;
    }

}

@media(max-width: 700px) {
    .bannerTextWidth {
        max-width: 75%;
        left: -20px;
        top: 180px;
    }

    .pusherDiv {
        padding-top: 140px;
    }
}

@media(max-width: 600px) {
    .bannerTextWidth {
        max-width: 100%;
        left: 0;
        top: 200px;
    }

    .pusherDiv {
        padding-top: 170px;
    }
}

@media(max-width: 400px) {
    .bannerTextWidth {
        top: 260px;
    }

    .pusherDiv {
        padding-top: 240px;
    }
}


/* banner css ends */