.wrapperDiv {
    display: flex;
    width: 100vw;
    min-height: calc(100vh - 120px);
    margin-top: 120px;
    height: 100%;
}

@media(max-width:767px) {
    .wrapperDiv {
        min-height: calc(100vh - 80px);
        margin-top: 0px;
    }
}